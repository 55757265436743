import React from "react"
import FormationCard from "../../Formations/FormationCard"
import { useAllFormations } from "../../../hooks/use-all-formations"

const ListeFormations = () => {
  const allStrapiFormation = useAllFormations()
  return (
    <div className="container mx-auto pt-12 pb-8">
      <div className="grid gap-6">
        {allStrapiFormation &&
          allStrapiFormation.edges.map((node, index) => {
            return <FormationCard key={index} content={node} />
          })}
      </div>
    </div>
  )
}

export default ListeFormations
