import React from "react"
import PropTypes from "prop-types"
import MarkdownRemark from "../../MarkdownRemark"

const PageDzTexteEnrichi = ({ block }) => {
  return (
    <section className={"text-enrichi overflow-hidden text-ellipsis"}>
      <MarkdownRemark contenu={block.texteEnrichi} />
    </section>
  )
}

PageDzTexteEnrichi.propTypes = {
  block: PropTypes.object.isRequired,
}

export default PageDzTexteEnrichi
