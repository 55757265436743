import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { SVGHeader } from "./SVGHeader"
import logoSVG from "../../images/SVG/logo_lhsl.svg";

const PageHeader = ({ illustration, title }) => {
  return (
    <section>
      <div className="relative overflow-hidden">
        <div className="wrapper-gatsby-image bg-ehsl-purple-400 bg-gradient-publication absolute inset-0">
          <img
            className="absolute -top-3 right-0 w-1/2 opacity-10"
            alt=""
            src={logoSVG}
            width={265}
            height={265}
          />
        </div>
        <div className="container mx-auto">
          <div className="flex h-72 items-center justify-center pl-5 lg:h-[32rem] lg:pr-96">
            <h1 className="text-shadow relative z-0 text-3xl font-bold text-white lg:text-7xl">
              {title}
            </h1>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-auto">
          <SVGHeader />
        </div>
      </div>
    </section>
  )
}

PageHeader.propTypes = {}

export default PageHeader
