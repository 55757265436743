import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { SVGHeader } from "./SVGHeader"

const PageHeader = ({ illustration, title }) => {
  return (
    <section>
      <div className="relative overflow-hidden">
        <div className="wrapper-gatsby-image absolute inset-0 object-cover">
          {illustration?.localFile?.childImageSharp?.gatsbyImageData && (
            <GatsbyImage className="h-full w-full" alt={""} image={illustration.localFile.childImageSharp.gatsbyImageData} />
          )}
        </div>
        <div className="container mx-auto">
          <div className="flex h-72 items-center justify-center pl-5 lg:h-[32rem] lg:pr-96">
            <h1 className="text-shadow relative z-0 text-3xl font-bold text-white lg:text-7xl">
              {title}
            </h1>
          </div>
        </div>
        <div className="absolute bottom-0 left-0 right-0 top-auto">
          <SVGHeader />
        </div>
      </div>
    </section>
  )
}

PageHeader.propTypes = {}

export default PageHeader
