import React from "react"

export function SVGHeader() {
  return (
    <svg
      id="ffc7bca1-1b15-42b2-bdbb-c3aa5c95f9e7"
      className="-mr-4 w-full"
      data-name="visual"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 901.05 115.61"
    >
      <path
        d="M-.25,87.34l50.11,4.6c50.1,4.61,150.31,13.82,250.4,17.41s200.07,1.57,299.82-11.7,199.28-37.76,249-50l49.76-12.25L900.8,130l-50,1L600.86,136l-299.94,6.08L51,147.19l-50,1Z"
        transform="translate(0.25 -35.39)"
        fill="#ac1e44"
      />
      <path
        d="M0,86l50,6c50,6,150,18,250,24s200,6,300-6S800,74,850,62l50-12V151H0Z"
        transform="translate(0.25 -35.39)"
        fill="#fff"
      />
    </svg>
  )
}
