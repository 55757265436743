import React from "react"
import PageDzGroupeDeCartes from "./PageDZGroupeDeCartes"
import PageDzTexteEnrichi from "./PageDZTexteEnrichi"
import PageDzGallerie from "./PageDZGallerie"
import FoireAuxQuestions from "../../Shared/FoireAuxQuestions"
import PageDzSousNavigation from "./PageDZSousNavigation"

const Index = ({ dynamicZone }) => {
  return dynamicZone.map(block => {
    switch (block.strapi_component) {
      case "page-dz.groupe-de-cartes":
        return <PageDzGroupeDeCartes block={block} />
      case "page-dz.texte-enrichi":
        return <PageDzTexteEnrichi block={block} />
      case "partage.foire-aux-questions":
        return <FoireAuxQuestions block={block} />
      case "page-dz.gallerie":
        return <PageDzGallerie block={block} />
      case "navigation.sous-navigation":
        return <PageDzSousNavigation block={block} />
      default:
        return null
    }
  })
}

export default Index
