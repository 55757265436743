import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const FormationCard = ({ content }) => {
  return (
    <Link
      title={content.node.nom_long || content.node.nom}
      to={`/${content.node.locale}/formations/${content.node.slug}`}
    >
      <div className="group relative flex items-center rounded-md rounded-tr-3xl border-l-4 border-l-ehsl-purple-500 bg-white shadow-xl transition-all hover:shadow-2xl">
        {content.node.illustration && (
          <div className="relative aspect-video w-full max-w-sm self-stretch xl:w-1/2">
            <div className="absolute inset-0 overflow-hidden bg-red-500">
              <GatsbyImage
                className="h-full w-full"
                objectFit="cover"
                alt={"formation illu"}
                image={
                  content.node.illustration.localFile.childImageSharp
                    .gatsbyImageData
                }
              />
            </div>
          </div>
        )}
        <div className="left prose prose-sm ml-4 py-4">
          <div className="nom-formation font-bold text-gray-900">
            {content.node.nom_long || content.node.nom}
          </div>
          <div className="py-4 pr-4 text-sm text-gray-600">
            {content.node.description}
          </div>
        </div>
        {content.node.informations && (
          <div className="right grow">
            <div className="ml-4 flex items-center gap-2 border-l border-l-gray-300 pl-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 fill-ehsl-purple-400"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm1-12a1 1 0 10-2 0v4a1 1 0 00.293.707l2.828 2.829a1 1 0 101.415-1.415L11 9.586V6z"
                  clipRule="evenodd"
                />
              </svg>
              <span>{content.node.informations.duree}</span>
            </div>
            {content.node.informations.possibiliteHebergement && (
              <div className="ml-4 flex items-center gap-2 border-l border-l-gray-300 pl-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5 fill-ehsl-purple-400"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path d="M10.707 2.293a1 1 0 00-1.414 0l-7 7a1 1 0 001.414 1.414L4 10.414V17a1 1 0 001 1h2a1 1 0 001-1v-2a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 001 1h2a1 1 0 001-1v-6.586l.293.293a1 1 0 001.414-1.414l-7-7z" />
                </svg>
                <span>
                  hébergement:{" "}
                  {content.node.informations.possibiliteHebergement
                    ? "oui"
                    : "non"}
                </span>
              </div>
            )}
          </div>
        )}
        <div className="absolute bottom-4 right-4">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 fill-ehsl-purple-400 transition-all group-hover:translate-x-2"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </div>
    </Link>
  )
}

FormationCard.propTypes = {
  content: PropTypes.object.isRequired,
}

export default FormationCard
