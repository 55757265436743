import React from "react"
import PropTypes from "prop-types"
import { usePartenaires } from "../../../hooks/use-partenaires"
import { GatsbyImage } from "gatsby-plugin-image"

const ListePartenaires = props => {
  const partenaires = usePartenaires()
  return (
    <section className="my-20">
      <div className="container mx-auto">
        {partenaires.edges.map(({ node }) => {
          return (
            <div className="partenaire-card max-w-7xl mt-10 grid grid-cols-5 justify-center items-center">
              <div className="col-span-2 mx-auto max-w-xs p-10">
                <a href={node.siteInternet}>
                  {(node.logo.mime === "image/gif" ||
                    node.logo.mime === "image/svg") && (
                    <img src={node.logo.localFile.publicUrl} />
                  )}
                  {(node.logo.mime !== "image/gif" ||
                    node.logo.mime !== "image/svg") && (
                    <GatsbyImage
                      alt={`Logo ${node.nom}`}
                      image={
                        node.logo.localFile.childImageSharp.gatsbyImageData
                      }
                    />
                  )}
                </a>
              </div>
              <div className="col-span-3 whitespace-pre-wrap bg-gray-100 py-10 px-4">
                <h2 className="text-4xl font-bold mb-5">{node.nom}</h2>
                {node.description}
                { node.siteInternet && <a className="block mt-4 underline underline-offset-2" rel="noopener noreferrer" href={node.siteInternet}>Visitez leur site internet</a>}
              </div>
            </div>
          )
        })}
      </div>
    </section>
  )
}

ListePartenaires.propTypes = {}

export default ListePartenaires
