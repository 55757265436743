import { graphql, useStaticQuery } from "gatsby"

export const useAllFormations = () => {
  const { allStrapiFormation } = useStaticQuery(
    graphql`
      query AllFormationsPage {
        allStrapiFormation {
          edges {
            node {
              description
              nom
              nom_long
              slug
              locale
              informations {
                duree
                alternance
                possibiliteHebergement
              }
              illustration {
                  mime
                localFile {
                  childImageSharp {
                    gatsbyImageData(width: 400)
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allStrapiFormation
}
