import React from "react"
import PropTypes from "prop-types"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Seo, { SEO } from "../components/seo";
import PageHeader from "../components/pages/PageHeader"
import Composants from "../components/pages/Composants"
import PageDynamicZone from "../components/pages/PageDynamicZone"
import PageHeaderNoIllustration from "../components/pages/PageHeaderNoIllustration"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"
import logoSVG from "../images/SVG/logo_lhsl.svg"

const PageTemplate = ({ location, data, pageContext }) => {
  const { strapiPage } = data
  console.log(pageContext);
  return (
    <Layout>
      <main className="pb-24">
        {strapiPage.illustration ? (
          <PageHeader
            illustration={strapiPage.illustration}
            title={strapiPage.titre}
          />
        ) : (
          <PageHeaderNoIllustration title={strapiPage.titre} />
        )}
        <div className="container mx-auto mb-12 py-4 px-4 md:px-0">
          <Link className="underline" to={"/"}>
            Accueil
          </Link>
          {strapiPage.page?.titre && (
            <>
              {" "}
              /{" "}
              <Link className="underline" to={`/fr/${strapiPage.page.slug}`}>
                {strapiPage.page.titre}
              </Link>
            </>
          )}
          <>
            {" "}
            / <span>{strapiPage.titre}</span>
          </>
        </div>
        {strapiPage.chilStrapiPresentationTextnode?.childMarkdownRemark
          ?.html && (
          <section className="container mx-auto">
            <div
              className="prose prose-xl"
              dangerouslySetInnerHTML={{
                __html:
                  strapiPage.chilStrapiPresentationTextnode.childMarkdownRemark
                    .html,
              }}
            />
          </section>
        )}
        <Composants composant={strapiPage.heroComposant} />
        <div className="dynamic-zone-page container relative mx-auto px-4 lg:px-0">
          <div className="grid lg:grid-cols-5 lg:gap-4">
            {strapiPage.contenu && (
              <div className="col-span-3">
                <PageDynamicZone dynamicZone={strapiPage.contenu} />
              </div>
            )}
            {strapiPage.piecesJointes && (
              <div className="col-span-2">
                <h4 className={"mb-4 font-sans text-2xl font-bold"}>
                  Documents à télécharger
                </h4>
                {strapiPage.piecesJointes.map(pj => {
                  return (
                    <a
                      className={"mb-2 block font-bold"}
                      href={pj.localFile.publicURL}
                    >
                      <FontAwesomeIcon icon={faDownload} /> {pj.name}{" "}
                      {pj.localFile.prettySize}
                    </a>
                  )
                })}
              </div>
            )}
          </div>
        </div>
      </main>
    </Layout>
  )
}

PageTemplate.propTypes = {
  location: PropTypes.any.isRequired,
  data: PropTypes.object.isRequired,
}

export default PageTemplate

export const Head = ({ data }) => (
  <SEO
    title={data.strapiPage.titre}
  />
)

export const query = graphql`
  query ($slug: String!) {
    strapiPage(slug: { eq: $slug }) {
      titre
      locale
      heroComposant
      page {
        titre
        slug
      }
      childStrapiPagePresentationTextnode {
        childMarkdownRemark {
          html
        }
      }
      illustration {
        localFile {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      piecesJointes {
        mime
        name
        localFile {
          publicURL
          extension
          ext
          name
          prettySize
        }
      }
      contenu {
        ... on STRAPI__COMPONENT_NAVIGATION_SOUS_NAVIGATION {
          strapi_component
          item {
            label
            titre
            destination
            illustration {
              id
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
                extension
                ext
              }
              ext
              mime
              name
              size
              width
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_DZ_GROUPE_DE_CARTES {
          strapi_component
          titre
          carteIllustree {
            titre
            contenu {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
            illustration {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PAGE_DZ_TEXTE_ENRICHI {
          strapi_component
          texteEnrichi {
            data {
              childMarkdownRemark {
                html
              }
            }
            medias {
              localFile {
                ext
                name
                publicURL
              }
            }
          }
        }
        ... on STRAPI__COMPONENT_PARTAGE_FOIRE_AUX_QUESTIONS {
          strapi_component
          question {
            question
            reponse {
              data {
                childMarkdownRemark {
                  html
                }
              }
            }
          }
        }
        ... STRAPI__COMPONENT_PAGE_DZ_GALLERIE
      }
    }
  }
`
