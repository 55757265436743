import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Gallery from "@browniebroke/gatsby-image-gallery"

const PageDzGallerie = ({ block }) => {
  const images = block.images.map(({ localFile }) => localFile.childImageSharp)

  return (
    <div className="my-12 mx-auto max-w-[1200px] px-4">
      <div className="text-2xl font-bold uppercase tracking-wide">Galerie</div>
      <Gallery images={images} />
    </div>
  )
}

PageDzGallerie.propTypes = {
  block: PropTypes.object.isRequired,
}

export default PageDzGallerie

export const query = graphql`
  fragment STRAPI__COMPONENT_PAGE_DZ_GALLERIE on STRAPI__COMPONENT_PAGE_DZ_GALLERIE {
    strapi_component
    images {
      localFile {
        childImageSharp {
          thumb: gatsbyImageData(width: 270, height: 270, placeholder: BLURRED)
          full: gatsbyImageData(layout: FULL_WIDTH)
        }
      }
    }
  }
`
