import React, { useState } from "react"
import PropTypes from "prop-types"
import classNames from "classnames"
import { motion } from "framer-motion"

const ItemFAQ = ({ item }) => {
  const [expanded, setExpanded] = useState(false)

  const answerVariant = {
    open: {
      height: "auto",
      opacity: 1,
    },
    closed: {
      height: 0,
      opacity: 0,
    },
  }

  const svgClasses = classNames("h-6 w-6 text-eshl-purple-500", {
    "rotate-180": expanded,
  })

  return (
    <div className="mt-4 border-b border-b-gray-300 pb-4">
      <button
        className="flex cursor-pointer justify-between font-bold"
        aria-roledescription={"obtenir la réponse"}
        onClick={() => setExpanded(!expanded)}
      >
        <span className="text-left">{item.question}</span>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className={svgClasses}
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          strokeWidth={2}
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19 9l-7 7-7-7"
          />
        </svg>
      </button>
      <motion.div
        initial="closed"
        animate={expanded ? "open" : "closed"}
        className="prose overflow-hidden text-gray-700"
        variants={answerVariant}
        dangerouslySetInnerHTML={{
          __html: item.reponse.data.childMarkdownRemark.html,
        }}
      />
    </div>
  )
}

const FoireAuxQuestions = ({ block }) => {
  return (
    <div id="faq">
      <div className="mx-auto max-w-2xl pt-12">
        <h2 className="relative z-20 m-0 inline-block pt-4 text-5xl font-bold text-gray-800 after:absolute after:left-0 after:-bottom-4 after:h-0.5 after:w-24 after:border-b-ehsl-purple-500 after:bg-ehsl-purple-500">
          Foire aux questions
        </h2>
        <div className="pt-12">
          {block.question.map(item => {
            return <ItemFAQ item={item} />
          })}
        </div>
      </div>
    </div>
  )
}

FoireAuxQuestions.propTypes = {
  block: PropTypes.object.isRequired
}

export default FoireAuxQuestions
