import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const Carte = ({ titre, illustration, contenu }) => {
  const imageData = getImage(illustration.localFile)
  console.log(contenu);
  return (
    <div className="card-wrapper">
      <div className="card-illustration">
        <div className="relative w-full aspect-video overflow-hidden">

          {imageData && (
            <GatsbyImage
              className="absolute inset-0"
              objectFit={"cover"}
              alt={illustration.localFile.alternativeText}
              image={imageData}
            />
          )}
        </div>
      </div>
      <h3 className="mt-6 mb-4 text-3xl font-bold">{titre}</h3>
      {contenu && contenu.data && (
        <div
          className="prose"
          dangerouslySetInnerHTML={{
            __html: contenu.data.childMarkdownRemark.html,
          }}
        />
      )}
    </div>
  )
}

const PageDzGroupeDeCartes = ({ block }) => {
  return (
    <div>
        {block.titre && (
          <h2 className="mb-6 text-5xl font-bold text-gray-800">
            {block.titre}
          </h2>
        )}
        <div className="grid grid-cols-2 gap-6">
          {block.carteIllustree.map(carte => {
            return (
              <Carte
                key={carte.titre}
                titre={carte.titre}
                illustration={carte.illustration}
                contenu={carte.contenu}
              />
            )
          })}
        </div>
    </div>
  )
}

PageDzGroupeDeCartes.propTypes = {
  titre: PropTypes.string.isRequired,
  illustration: PropTypes.object.isRequired,
  contenu: PropTypes.object.isRequired
}

export default PageDzGroupeDeCartes
