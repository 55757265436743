import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { Link } from "gatsby";

const Video = ({ src }) => {
  const mouseOverHandler = e => e.currentTarget.play()
  const mouseOutHandler = e => e.currentTarget.pause()
  return (
    <video
      onMouseOver={mouseOverHandler}
      onFocus={mouseOverHandler}
      onMouseOut={mouseOutHandler}
      onBlur={mouseOutHandler}
      className="h-full w-full rounded-lg object-cover"
      preload="none"
      muted
      loop
    >
      <source src={src} type="video/mp4" />
      Désolé votre navigateur ne prend pas en charge les videos
    </video>
  )
}

const BackgroundMedia = ({ media }) => {
  switch (media.mime) {
    case "image/jpeg":
      return (
        <GatsbyImage
          className="h-full w-full rounded-lg"
          alt={media.name}
          objectFit={"cover"}
          image={media.localFile.childImageSharp.gatsbyImageData}
        />
      )
    case "video/mp4":
      return <Video src={media.localFile.publicURL} />
    default:
      return null
  }
}

const SousNavigationCarte = ({ carte }) => {
  return (
    <Link to={carte.destination} className="video-shadow group relative flex cursor-pointer items-center justify-center rounded-lg bg-gray-600 bg-clip-border transition-all hover:scale-105 hover:bg-gray-300">
      <div className="">
        <div className="absolute inset-0 z-10 box-border rounded-lg border-4 border-gray-500 bg-gradient-to-t from-black/20 to-transparent bg-clip-border group-hover:border-gray-300">
          {carte.illustration && <BackgroundMedia media={carte.illustration} />}
        </div>
      </div>
      <div className="text-shadow pointer-events-none relative z-20 my-6 text-xl font-bold text-white lg:my-16">
        {carte.label}
      </div>
    </Link>
  )
}

const PageDzSousNavigation = ({ block }) => {
  return (

        <div className="grid grid-cols-3 gap-4 lg:grid-cols-4">
          {block.item.map(item => {
            return <SousNavigationCarte carte={item} />
          })}
        </div>

  )
}

PageDzSousNavigation.propTypes = {
  block: PropTypes.object.isRequired
}

export default PageDzSousNavigation
