import React from "react"
import PropTypes from "prop-types"
import ListeFormations from "./ListeFormations"
import ListeActualites from "./ListeActualites"
import ListePartenaires from "./ListePartenaires";

const Index = ({ composant }) => {
  switch (composant) {
    case "listeFormation":
      return <ListeFormations />
    case "listeActualites":
      return <ListeActualites />
    case "listePartenaires":
      return <ListePartenaires />
    default:
      return null
  }
}

Index.propTypes = {
  composant: PropTypes.string
}

export default Index
