import React from "react"
import PropTypes from "prop-types"

const Index = ({ contenu }) => {
  let html = contenu.data?.childMarkdownRemark?.html ? contenu.data?.childMarkdownRemark?.html : ""

  if (contenu.medias.length > 0) {
    contenu.medias.forEach(media => {
      html = html.replace(
        `/uploads/${media.localFile.name}${media.localFile.ext}`,
        `${media.localFile.publicURL}`
      )
    })
  }

  return (
    <div
      className="prose md:prose-lg lg:prose-xl mx-auto overflow-hidden text-ellipsis max-w-full break-words"
      dangerouslySetInnerHTML={{
        __html: html,
      }}
    />
  )
}

Index.propTypes = {
  contenu: PropTypes.object.isRequired
}

export default Index
