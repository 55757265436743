import { graphql, useStaticQuery } from "gatsby"

export const usePartenaires = () => {
  const { allStrapiPartenaire } = useStaticQuery(
    graphql`
      query {
        allStrapiPartenaire(sort: { fields: nom, order: ASC }) {
          edges {
            node {
              nom
              description
              siteInternet
              logo {
                  mime
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
          }
        }
      }
    `
  )
  return allStrapiPartenaire
}
