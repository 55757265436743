import React from "react"
import PropTypes from "prop-types"
import { useAllActualites } from "../../../hooks/use-all-actualites"
import { Link } from "gatsby"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"

const Article = ({ node }) => {
  return (
    <Link to={`/${node.locale}/publications/${node.publishedAtURL}/${node.slug}`}>
      <article className="flex gap-4 border-b-2 border-b-gray-400 py-6">
        <div>
          {node.illustration && (
            <GatsbyImage
              alt={node.illustration.localFile}
              className="aspect-square w-24"
              objectFit={"cover"}
              image={
                node.illustration.localFile.childImageSharp.gatsbyImageData
              }
            />
          )}
          {!node.illustration && (
            <StaticImage
              alt={""}
              className="aspect-square w-24 opacity-30 grayscale"
              objectFit={"cover"}
              src="../../../images/etablissement.jpg"
            />
          )}
        </div>
        <div>
          <header>
            {node.categorie && (
              <div className="text-xs uppercase text-ehsl-purple-800">
                {node.categorie.nom}
              </div>
            )}
            <div className="text-xs uppercase text-gray-800">
              Publié le {node.publishedAtHumanized}
            </div>
            <div className="pt-6 text-2xl font-bold text-gray-800">
              {node.titre}
            </div>
          </header>
          {node.contenu.data && (
            <p className="text-gray-500">
              {node.contenu.data.childMarkdownRemark.excerpt}
            </p>
          )}
        </div>
      </article>
    </Link>
  )
}

const ListeActualites = ({ publications }) => {

  return (
    <>
      <div className="mx-auto max-w-6xl py-12">
        {publications.map(( node ) => {
          return <Article key={node.titre} node={node} />
        })}
      </div>
    </>
  )
}

ListeActualites.propTypes = {
  publications: PropTypes.object.isRequired
}

export default ListeActualites
